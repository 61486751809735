<template>
  <div>
    <div class="form-page" v-if="Enabled">
      <choiceLang />

      <div class="form-title">
        <span class="text">
          <span v-if="lang === 'zh'">{{
            personalHealthQnrDesign.TitleChinese
          }}</span>
          <span v-if="lang === 'en'">{{
            personalHealthQnrDesign.TitleEnglish
          }}</span>
        </span>
        <!-- <img src="@/assets/img/guy.png" alt="" /> -->

        <div class="page-number">
          <span>{{ currentPage }}</span
          >/{{ categories.length + 3 }}
        </div>
      </div>
      <!-- <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
                    <el-step v-for="i in 6" :key="i" :title="'步驟'+i" ></el-step>
                </el-steps>


                <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->

      <div class="form-wrap">
        <ValidationObserver v-slot="{ valid, changed, validated }" ref="form">
          <form @submit.prevent="addPersonalHealthQuestionnaire">
            <div v-if="currentPage === 1" class="page">
              <div class="title">
                <span>
                  <span v-if="lang === 'zh'">個人基本資料</span>
                  <span v-if="lang === 'en'">Personal Information</span>
                </span>
              </div>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="field">
                  <input
                    type="text"
                    :placeholder="i18n('PlaceHolder.BadgeNumber')"
                    v-model="staffInfo.BadgeNo"
                    @blur="getStaffName"
                  />
                </div>
                <span v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="field">
                  <input
                    type="text"
                    :placeholder="i18n('PlaceHolder.Name')"
                    v-model="staffInfo.Name"
                    readonly
                  />
                </div>
                <span v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>

              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="title">
                  <span>
                    <span v-if="lang === 'zh'">出勤</span>
                    <span v-if="lang === 'en'">On Duty</span>
                  </span>
                </div>
                <label class="checkbox">
                  <input
                    type="radio"
                    value="在公司上班"
                    v-model="questionnaire.DutyStatus"
                  />

                  <span class="text">
                    <span v-if="lang === 'zh'">在公司上班</span>
                    <span v-if="lang === 'en'">Work at the company</span>
                  </span>
                </label>

                <label class="checkbox">
                  <input
                    type="radio"
                    value="居家上班"
                    v-model="questionnaire.DutyStatus"
                  />

                  <span class="text">
                    <span v-if="lang === 'zh'">居家上班</span>
                    <span v-if="lang === 'en'">Work from home</span>
                  </span>
                </label>

                <div class="title">
                  <span>
                    <span v-if="lang === 'zh'">未出勤</span>
                    <span v-if="lang === 'en'">Off Duty</span>
                  </span>
                </div>
                <label class="checkbox">
                  <input
                    type="radio"
                    value="組休"
                    v-model="questionnaire.DutyStatus"
                  />

                  <span class="text">
                    <span v-if="lang === 'zh'">組休</span>
                    <span v-if="lang === 'en'">Off duty day</span>
                  </span>
                </label>
                <label class="checkbox">
                  <input
                    type="radio"
                    value="請假"
                    v-model="questionnaire.DutyStatus"
                  />

                  <span class="text">
                    <span v-if="lang === 'zh'">請假</span>
                    <span v-if="lang === 'en'">Take what kind of leave</span>
                  </span>
                </label>

                <div class="field" v-if="questionnaire.DutyStatus === '請假'">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      id="inp"
                      :placeholder="i18n('PlaceHolder.Leave')"
                      v-model="questionnaire.Leave"
                    />
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <span v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div v-if="currentPage === 2" class="page">
              <div class="title">
                <!-- <span>一、最新疫調足跡史確認 </span> -->
                <!-- <span>一、今日無公告新足跡 <br>There is no announcement of the latest footprints today.</span> -->
                <span>
                  <span v-if="lang === 'zh'">公告足跡史及重點疫調項目</span>
                  <span v-if="lang === 'en'"
                    >Latest footprints and important survey items</span
                  >
                </span>
              </div>

              <ValidationProvider rules="required" v-slot="{ errors }">
                <label
                  class="checkbox"
                  v-for="item in newFootprints"
                  :key="item.ID"
                >
                  <input
                    type="checkbox"
                    :value="item.Chinese"
                    v-model="questionnaire.Footprint"
                    @change="changeLatestFootprints(item.Chinese)"
                  />
                  <span class="text">
                    <span v-if="lang === 'zh'">{{ item.Chinese }} </span>
                    <span v-if="lang === 'en'">{{ item.English }}</span>
                  </span>
                </label>

                <span v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div
              v-for="(category, index) in categories"
              :key="category.Guid"
              class="page"
            >
              <div v-if="currentPage === index + 3">
                <div class="title">
                  <span v-if="lang === 'zh'">{{ category.SurveyChinese }}</span>
                  <span v-if="lang === 'en'">{{ category.SurveyEnglish }}</span>
                </div>

                <div
                  v-for="question in category.ContentJson"
                  :key="question.Guid"
                >
                  <div class="desc">
                    <span v-if="lang === 'zh'">{{
                      question.FieldName_zhTW
                    }}</span>
                    <span v-if="lang === 'en'">{{
                      question.FieldName_enUS
                    }}</span>
                  </div>

                  <!-- input -->
                  <ValidationProvider
                    v-if="question.FieldType === 'Input'"
                    :rules="{
                      required: question.Required !== null && question.Required,
                    }"
                    v-slot="{ errors }"
                  >
                    <div class="field">
                      <input
                        type="text"
                        v-model="question.Value"
                        maxlength="10000"
                      />
                    </div>
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>

                  <!-- textarea -->
                  <ValidationProvider
                    v-if="question.FieldType === 'TextArea'"
                    :rules="{
                      required: question.Required !== null && question.Required,
                    }"
                    v-slot="{ errors }"
                  >
                    <div class="field">
                      <textarea
                        style="min-height: 112px"
                        v-model="question.Value"
                        maxlength="10000"
                      ></textarea>
                    </div>
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>

                  <!-- radio -->
                  <ValidationProvider
                    v-if="question.FieldType === 'Radio'"
                    :rules="{
                      required: question.Required !== null && question.Required,
                    }"
                    v-slot="{ errors }"
                  >
                    <label
                      class="checkbox"
                      v-for="option in question.Option"
                      :key="option.OptionsName_zhTW"
                    >
                      <input
                        type="radio"
                        :value="option.OptionsName_zhTW"
                        v-model="question.Value"
                      />
                      <span class="text">
                        <span v-if="lang === 'zh'">{{
                          option.OptionsName_zhTW
                        }}</span>
                        <span v-if="lang === 'en'">{{
                          option.OptionsName_enUS
                        }}</span>
                      </span>
                    </label>

                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>

                  <!-- checkbox -->
                  <ValidationProvider
                    v-if="question.FieldType === 'Checkbox'"
                    :rules="{
                      required: question.Required !== null && question.Required,
                    }"
                    v-slot="{ errors }"
                  >
                    <label
                      class="checkbox symptom-item"
                      v-for="option in question.Option"
                      :key="option.OptionsName_zhTW"
                    >
                      <input
                        type="checkbox"
                        :value="option.OptionsName_zhTW"
                        v-model="question.Value"
                      />
                      <span class="text">
                        <span v-if="lang === 'zh'">{{
                          option.OptionsName_zhTW
                        }}</span>
                        <span v-if="lang === 'en'">{{
                          option.OptionsName_enUS
                        }}</span>
                      </span>
                    </label>
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>

                  <!-- date -->
                  <ValidationProvider
                    v-if="question.FieldType === 'DateTime'"
                    :rules="{
                      required: question.Required !== null && question.Required,
                    }"
                    v-slot="{ errors }"
                  >
                    <!-- <div class="field">
                      <el-date-picker type="date" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="question.Value">
                      </el-date-picker>
                    </div> -->
                    <div class="field">
                      <el-date-picker
                        type="date"
                        value-format="yyyy-MM-dd"
                        v-model="question.Value"
                      >
                      </el-date-picker>
                    </div>
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div v-if="currentPage === categories.length + 3" class="page">
              <div class="title">
                <span>
                  <span v-if="lang === 'zh'">快篩結果回報</span>
                  <span v-if="lang === 'en'"
                    >Feedback of Rapid Test Result</span
                  >
                </span>
              </div>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label class="checkbox">
                  <input type="radio" value="陰性" v-model="RapidTestResult" />
                  <span class="text">
                    <span v-if="lang === 'zh'">陰性</span>
                    <span v-if="lang === 'en'">Negative</span>
                  </span>
                </label>
                <label class="checkbox">
                  <input type="radio" value="陽性" v-model="RapidTestResult" />
                  <span class="text">
                    <span v-if="lang === 'zh'">陽性</span>
                    <span v-if="lang === 'en'">Positive</span>
                  </span>
                </label>
                <label class="checkbox">
                  <input
                    type="radio"
                    value="無效反應"
                    v-model="RapidTestResult"
                  />
                  <span class="text">
                    <span v-if="lang === 'zh'">無效反應</span>
                    <span v-if="lang === 'en'">Invalid</span>
                  </span>
                </label>
                <label class="checkbox">
                  <input
                    type="radio"
                    value="本人今日沒有做快篩"
                    v-model="RapidTestResult"
                  />
                  <span class="text">
                    <span v-if="lang === 'zh'">本人今日沒有做快篩</span>
                    <span v-if="lang === 'en'"
                      >I don't do rapid test today</span
                    >
                  </span>
                </label>
                <span v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
              <template
                v-if="
                  RapidTestResult === '陰性' ||
                  RapidTestResult === '陽性' ||
                  RapidTestResult === '無效反應'
                "
              >
                <div class="title">
                  <span>
                    <span v-if="lang === 'zh'"> 快篩照片上傳 </span>
                    <span v-if="lang === 'en'">
                      Upload the rapid test result picture
                    </span>
                  </span>
                </div>
                <label v-if="!fileGuid" class="btn btn-info">
                  <input
                    style="display: none"
                    type="file"
                    @change="uploadFile"
                  />
                  <i class="fa fa-photo"></i>
                  {{ lang === "zh" ? "上傳" : "upload" }}
                </label>

                <div v-else class="img-style">
                  <eva-icon
                    class="icon"
                    fill="#c0ccda"
                    name="close-outline"
                    @click="fileRemove"
                  />
                  <img :src="fileImg" />
                </div>
              </template>
            </div>

            <div class="button-wrap">
              <button
                type="button"
                v-show="currentPage > 1"
                @click.self="currentPage -= 1"
                class="btn btn-secondary"
              >
                {{ i18n("Buttons.PreviousPage") }}
              </button>
              <button
                type="submit"
                v-show="currentPage < categories.length + 3"
                class="btn btn-warning"
              >
                {{ i18n("Buttons.NextPage") }}
              </button>
              <button
                type="submit"
                v-show="currentPage === categories.length + 3"
                class="btn btn-warning"
              >
                {{ i18n("Buttons.Submit") }}
              </button>
              <span
                style="cursor: default; width: auto"
                v-if="
                  (changed && !valid && validated) ||
                  (!changed && !valid && validated)
                "
                class="btn text-danger"
                >{{ i18n("vee-validations.Alert") }}</span
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div class="form-page" v-else>
      <div class="prohibit">
        <img src="@/assets/img/prohibit.png" alt="" />
        <p>此問卷尚未開放</p>
      </div>
    </div>

    <!-- 預覽 Modal -->
    <div
      id="previewModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header title">
            <span>填答結果</span>
          </div>
          <div class="modal-body">
            <p class="text-danger">
              【您有填寫以下內容，如需修改請回問卷調整】
            </p>

            <div class="mb-4">
              <p class="category-title">1. 公告足跡史及重點疫調項目</p>
              <span
                v-for="(item, index) in questionnaire.Footprint"
                :key="item"
              >
                <span v-if="index > 0">、</span>
                {{ item }}
              </span>
            </div>

            <div
              v-for="(category, categoryIndex) in categories"
              :key="category.Guid"
            >
              <p class="category-title">
                {{ categoryIndex + 2 }}. {{ category.SurveyChinese }}
              </p>

              <div
                v-for="(ques, quesIndex) in category.ContentJson"
                :key="ques.Guid"
              >
                <p v-if="ques.FieldType === 'Checkbox'">
                  {{ categoryIndex + 2 }}-{{ quesIndex + 1 }}.
                  {{ ques.FieldName_zhTW }}：
                  <span v-for="(item, index) in ques.Value" :key="item">
                    <span v-if="index > 0">、</span>
                    {{ item }}
                  </span>
                </p>
                <p v-else-if="ques.FieldType !== 'Remark'">
                  {{ categoryIndex + 2 }}-{{ quesIndex + 1 }}.
                  {{ ques.FieldName_zhTW }}： {{ ques.Value }}
                </p>
              </div>
            </div>

            <div>
              <p class="category-title">
                {{ categories.length + 2 }}. 快篩結果回報
              </p>

              <div>
                <p>
                  {{ categories.length + 2 }}-1. 快篩結果：
                  {{ RapidTestResult }}
                </p>
                <p v-if="RapidTestResult !== '本人今日沒有做快篩'">
                  {{ categories.length + 2 }}-2. 快篩圖片：<img
                    :src="fileImg"
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button
              class="btn btn-success"
              data-dismiss="modal"
              :key="index"
              @click.prevent.once="submitQuestionnaire"
            >
              確認送出
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import moment from "moment";
import EXIF from "exif-js";
import { mapGetters, mapActions } from "vuex";

import { apiGetStaffName, apiGetFootprintAll } from "@/apis/common";
import {
  apiAddPersonalHealthQuestionnaire,
  apiGetPHSurveyItemsAll,
  apiCheckPersonalHealthQnrEnabled,
  apiGetRapidTestPicBase64,
} from "@/apis/questionnaire";

import choiceLang from "@/components/common/choiceLang.vue";

export default {
  components: {
    choiceLang,
  },
  data() {
    return {
      currentPage: 1,
      active: 0,

      // 快篩結果
      RapidTestResult: "",
      // 快篩圖檔 Guid
      fileGuid: "",
      // api 回傳快篩圖檔
      fileImg: null,
      // file
      fileData: null,
      // 旋轉角度
      orientation: null,

      check: null,
      check1: null,
      check2: null,
      symptom: [
        //  症狀
        {
          key: "1",
          name: "發燒 fever",
          value: "發燒",
        },
        {
          key: "2",
          name: "喉嚨痛 sore throat",
          value: "喉嚨痛",
        },
        {
          key: "3",
          name: "咳嗽 cough",
          value: "咳嗽",
        },
        {
          key: "4",
          name: "嗅/味覺改變 change in taste/smell",
          value: "嗅/味覺改變",
        },
        {
          key: "5",
          name: "流鼻水 runny nose",
          value: "流鼻水",
        },
        {
          key: "6",
          name: "全身痠痛 body pain",
          value: "全身痠痛",
        },
        {
          key: "7",
          name: "胸痛/胸悶 chest pain/tightness",
          value: "胸痛/胸悶",
        },
        {
          key: "8",
          name: "呼吸困難 hard breathing",
          value: "呼吸困難",
        },
        {
          key: "9",
          name: "頭痛 head ache",
          value: "頭痛",
        },
        {
          key: "10",
          name: "接種疫苗後發燒 fever after vaccination",
          value: "接種疫苗後發燒",
        },
        {
          key: "1",
          name: "其他 others",
          value: "其他",
        },
        {
          key: "12",
          name: "以上皆無 None of the above",
          value: "以上皆無",
        },
      ],
      RapidTestReasonOption: [
        {
          name: "個人健康需求",
          Enname: "Personal health needs",
        },
        {
          name: "涉及高風險職業場所(服務業、醫院、學校)",
          Enname:
            "I have been to place of high-risk occupation (service industry, hospital, school…, etc.)",
        },
        {
          name: "跨縣市移動",
          Enname: "I have moved cross county",
        },
        {
          name: "高風險足跡重疊",
          Enname: "I have had footprints of confirmed COVID-19 cases",
        },
        {
          name: "接觸外部廠商",
          Enname: "I have had contact with outside manufacturers",
        },
        {
          name: "群聚風險(喪禮、市場、聚會)",
          Enname: "I have had the risk of cluster(funeral, market, party)",
        },
        {
          name: "搭乘大眾交通工具通勤",
          Enname: "I have taken the public transportation for commuting",
        },
      ],
      newFootprints: [],
      staffInfo: {
        BadgeNo: null, //  工號
        Name: null,
      },
      questionnaire: {
        // attendance: '',
        temperature: "",
        temperatureByFamily: "",

        // latestFootprints: [],
        Footprint: [],

        DutyStatus: "",
        Leave: "",
        RapidTestWish: null,
        RapidTestDate: null,
        RapidTestReason: [],
        VaccinationStatus: "",
        FirstVaccinationDate: null,
        SecondVaccinationDate: null,
        DailySurvey: [],
        tempDailySurvey: [], //  暫存

        //  三
        HealthConditionByMyself: [],
        OtherByMyself: null,
        ControlList: false,
        ForeheadByMyself: null,
        EarByMyself: null,
        ArmpitByMyself: null,
        BeginDateByMyself: null,
        BodyTemperatureByMyself: [],
        // SeeDoctorByMyself: false,
        SeeDoctorByMyself: [],
        SeeDoctorDateByMyself: null,
        FindingsByMyself: null,
        HospitalNameByMyself: null,

        MeasuringPosition: [],

        //  四
        HealthConditionByFamily: [],
        OtherByFamily: null,
        BodyTemperatureByFamily: null,
        ForeheadByFamily: null,
        EarByFamily: null,
        ArmpitByFamily: null,
        BeginDateByFamily: null,
        ComeBackDate: null,
        SeeDoctorByFamily: [],
        SeeDoctorDateByFamily: null,
        FindingsByFamily: null,
        HospitalNameByFamily: null,

        MeasuringPositionByFamily: [],

        // 五
        HaveComments: false,
        Comments: null,
      },
      otherByMyself: false,
      otherByFamily: false,
      currentDate: "",
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      futureDatePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        },
      },
      pickerOptions: {
        disabledDate(time) {
          return new Date(time).getDay() === 1;
        },
      },
      pickerWeekendOptions: {
        disabledDate(time) {
          return new Date(time).getDay() === 0 || new Date(time).getDay() === 6;
        },
      },

      categories: [],
      Enabled: false, //  是否啟用
      index: 0, //  避免被once後，無法再次送出
    };
  },
  watch: {
    "questionnaire.MeasuringPosition": function () {
      if (this.questionnaire.MeasuringPosition.length === 2) {
        this.questionnaire.MeasuringPosition.splice(0, 1);
      }
    },
    "questionnaire.temperature": function () {
      if (this.questionnaire.MeasuringPosition[0] === "額溫") {
        this.questionnaire.ForeheadByMyself = this.questionnaire.temperature;
        this.questionnaire.EarByMyself = null;
        this.questionnaire.ArmpitByMyself = null;
      } else if (this.questionnaire.MeasuringPosition[0] === "耳溫") {
        this.questionnaire.EarByMyself = this.questionnaire.temperature;
        this.questionnaire.ForeheadByMyself = null;
        this.questionnaire.ArmpitByMyself = null;
      } else if (this.questionnaire.MeasuringPosition[0] === "腋溫") {
        this.questionnaire.ArmpitByMyself = this.questionnaire.temperature;
        this.questionnaire.ForeheadByMyself = null;
        this.questionnaire.EarByMyself = null;
      }
    },
    "questionnaire.BodyTemperatureByMyself": function () {
      if (this.questionnaire.BodyTemperatureByMyself.length === 2) {
        this.questionnaire.BodyTemperatureByMyself.splice(0, 1);
      }
    },
    "questionnaire.SeeDoctorByMyself": function () {
      if (this.questionnaire.SeeDoctorByMyself.length === 2) {
        this.questionnaire.SeeDoctorByMyself.splice(0, 1);
      }
    },
    "questionnaire.MeasuringPositionByFamily": function () {
      if (this.questionnaire.MeasuringPositionByFamily.length === 2) {
        this.questionnaire.MeasuringPositionByFamily.splice(0, 1);
      }
    },
    "questionnaire.temperatureByFamily": function () {
      if (this.questionnaire.MeasuringPositionByFamily[0] === "額溫") {
        this.questionnaire.ForeheadByFamily =
          this.questionnaire.temperatureByFamily;
        this.questionnaire.EarByFamily = null;
        this.questionnaire.ArmpitByFamily = null;
      } else if (this.questionnaire.MeasuringPositionByFamily[0] === "耳溫") {
        this.questionnaire.EarByFamily = this.questionnaire.temperatureByFamily;
        this.questionnaire.ForeheadByFamily = null;
        this.questionnaire.ArmpitByFamily = null;
      } else if (this.questionnaire.MeasuringPositionByFamily[0] === "腋溫") {
        this.questionnaire.ArmpitByFamily =
          this.questionnaire.temperatureByFamily;
        this.questionnaire.ForeheadByFamily = null;
        this.questionnaire.EarByFamily = null;
      }
    },
    "questionnaire.SeeDoctorByFamily": function () {
      if (this.questionnaire.SeeDoctorByFamily.length === 2) {
        this.questionnaire.SeeDoctorByFamily.splice(0, 1);
      }
    },
    "questionnaire.Comments": function () {
      if (this.questionnaire.Comments.length > 0) {
        this.questionnaire.HaveComments = true;
      } else {
        this.questionnaire.HaveComments = false;
      }
    },
    currentPage: function () {
      this.$refs.form.reset();

      this.goTop();

      // if(this.currentPage === (this.categories.length+2)) {
      //     this.checkPersonalHealthQnrEnabled()
      // }
    },
    "questionnaire.HealthConditionByMyself": function () {
      let isHave = this.questionnaire.HealthConditionByMyself.find((item) => {
        return item === "其他";
      });

      if (isHave !== undefined) {
        this.otherByMyself = true;
      } else {
        this.otherByMyself = false;
      }

      // if(this.questionnaire.HealthConditionByMyself.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.HealthConditionByMyself = ['以上皆無']
      // }
    },
    "questionnaire.HealthConditionByFamily": function () {
      let isHave = this.questionnaire.HealthConditionByFamily.find((item) => {
        return item === "其他";
      });

      if (isHave !== undefined) {
        this.otherByFamily = true;
      } else {
        this.otherByFamily = false;
      }

      // if(this.questionnaire.HealthConditionByFamily.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.HealthConditionByFamily = ['以上皆無']
      // }
    },

    RapidTestResult: function () {
      if (this.RapidTestResult === "本人今日沒有做快篩") {
        this.fileGuid = "";
        this.fileImg = null;
      }
    },
  },
  computed: {
    ...mapGetters(["personalHealthQnrDesign", "lang"]),
  },
  methods: {
    ...mapActions(["getPersonalHealthQnrDesign", "updateLoading"]),
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 刪除檔案
    fileRemove() {
      this.fileGuid = "";
      this.fileImg = null;
    },

    // 上傳照片
    uploadFile(upload) {
      this.fileData = upload.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (e) => {
        this.getOrientation(e.target.result);
      };
    },

    // 取得拍攝角度
    getOrientation(res) {
      var img = new Image();
      img.src = res;
      img.onload = () => {
        EXIF.getData(this.fileData, () => {
          const imageOrientation = EXIF.getTag(this.fileData, "Orientation");
          imageOrientation
            ? (this.orientation = Number(imageOrientation))
            : (this.orientation = 1);
          this.uploadRapidTestPic();
        });
      };
    },

    // 上傳圖檔 API
    uploadRapidTestPic() {
      const api = `${window.BaseUrl.api}/FileUpload/UploadRapidTestPic`;

      let fd = new FormData();
      fd.append("File", this.fileData);
      fd.append("Orientation", this.orientation);
      fd.append("CreateUser", this.staffInfo.Name);
      this.updateLoading(true);

      this.$http
        .post(api, fd, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          this.fileGuid = response.data;
          apiGetRapidTestPicBase64({
            photoFileGroupId: this.fileGuid,
          })
            .then((response) => {
              this.updateLoading(false);
              this.fileImg = response.data;
            })
            .catch(() => {
              this.updateLoading(false);
            });
        })
        .catch(() => {
          this.updateLoading(false);
          this.fileGuid = "";
          this.$notify({
            title: "失敗",
            message: "檔案上傳失敗",
            type: "error",
            duration: 1500,
          });
        });
    },

    next() {
      if (this.active++ > 2) this.active = 0;
    },

    getStaffName() {
      apiGetStaffName({
        badgeNo: this.staffInfo.BadgeNo,
      })
        .then((response) => {
          if (response.status === 200) {
            this.staffInfo.Name = response.data;
          }
        })
        .catch(() => {
          this.staffInfo.BadgeNo = null;
          this.$refs.form.reset();
        });
    },
    async addPersonalHealthQuestionnaire() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (this.RapidTestResult === "陰性" || this.RapidTestResult === "陽性" || this.RapidTestResult === "無效反應") {
        if (!this.fileGuid) {
          this.$notify({
            title: "錯誤",
            message: "未上傳快篩照片",
            type: "error",
            duration: 2000,
          });
          return;
        }
      }

      if (this.currentPage === this.categories.length + 3) {
        await this.checkPersonalHealthQnrEnabled();

        if (this.Enabled) {
          $("#previewModal").modal("show");
        } else {
          this.$notify({
            title: "錯誤",
            message: "此問卷尚未開放",
            type: "error",
            duration: 3500,
          });
        }
      } else {
        this.currentPage += 1;
      }
    },
    async submitQuestionnaire() {
      let obj = this.categories.map((category) => {
        let tempQuestion = [];
        category.ContentJson.map((question) => {
          if (question.FieldType === "Checkbox") {
            tempQuestion.push({
              ...question,
              Value: question.Value.join("、"),
            });
          } else {
            tempQuestion.push({
              ...question,
            });
          }
        });

        return {
          ...category,
          ContentJson: tempQuestion,
        };
      });

      await apiAddPersonalHealthQuestionnaire({
        ...this.staffInfo,
        DutyStatus: this.questionnaire.DutyStatus,
        Leave: this.questionnaire.Leave,
        Footprint: this.questionnaire.Footprint,
        DataContent: obj,
        RapidTestResult: this.RapidTestResult,
        RapidTestPic: this.fileGuid,
      })
        .then((response) => {
          // console.log(response);

          if (response.status === 200) {
            this.$notify({
              title: "成功",
              message: "已成功送出",
              type: "success",
              duration: 3500,
            });

            // this.$router.push('finish')
            this.$router.push({
              name: "Finish",
              params: { staff: JSON.stringify(this.staffInfo) },
            });
          }
        })
        .catch(() => {
          this.index++;
        });
    },
    goTop() {
      $("html,body").animate({ scrollTop: 0 }, "slow"); /* 返回到最頂上 */
      return false;
    },
    changeDailySurvey(item) {
      // console.log('123132')
      if (
        item !== "以上皆無" &&
        this.questionnaire.DailySurvey.indexOf("以上皆無") !== -1
      ) {
        // console.log('1',this.questionnaire.DailySurvey)
        this.questionnaire.DailySurvey.splice(0, 1);
        // console.log('2',this.questionnaire.DailySurvey)
      } else if (item !== "以上皆無") {
        // console.log('3',this.questionnaire.DailySurvey)
        // this.questionnaire.DailySurvey.slice(1)
        // console.log('4',this.questionnaire.DailySurvey)
      } else if (
        item === "以上皆無" &&
        this.questionnaire.DailySurvey.indexOf("以上皆無") !== -1
      ) {
        this.questionnaire.DailySurvey = ["以上皆無"];
      }
      // if(this.questionnaire.DailySurvey.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.DailySurvey = ['以上皆無']
      // }
    },
    changeHealthConditionByMyself(item) {
      // console.log('Myself')
      if (
        item !== "以上皆無" &&
        this.questionnaire.HealthConditionByMyself.indexOf("以上皆無") !== -1
      ) {
        // console.log('1',this.questionnaire.HealthConditionByMyself)
        this.questionnaire.HealthConditionByMyself.splice(0, 1);
        // console.log('2',this.questionnaire.HealthConditionByMyself)
      } else if (item !== "以上皆無") {
        // console.log('3',this.questionnaire.HealthConditionByMyself)
        // this.questionnaire.HealthConditionByMyself.slice(1)
        // console.log('4',this.questionnaire.HealthConditionByMyself)
      } else if (
        item === "以上皆無" &&
        this.questionnaire.HealthConditionByMyself.indexOf("以上皆無") !== -1
      ) {
        this.questionnaire.HealthConditionByMyself = ["以上皆無"];
      }
      // if(this.questionnaire.HealthConditionByMyself.indexOf('以上皆無') !== -1) {
      // this.questionnaire.HealthConditionByMyself = ['以上皆無']
      // }
    },
    changeHealthConditionByFamily(item) {
      // console.log('Family')
      if (
        item !== "以上皆無" &&
        this.questionnaire.HealthConditionByFamily.indexOf("以上皆無") !== -1
      ) {
        // console.log('1',this.questionnaire.HealthConditionByMyself)
        this.questionnaire.HealthConditionByFamily.splice(0, 1);
        // console.log('2',this.questionnaire.HealthConditionByMyself)
      } else if (item !== "以上皆無") {
        // console.log('3',this.questionnaire.HealthConditionByMyself)
        // this.questionnaire.HealthConditionByMyself.slice(1)
        // console.log('4',this.questionnaire.HealthConditionByMyself)
      } else if (
        item === "以上皆無" &&
        this.questionnaire.HealthConditionByFamily.indexOf("以上皆無") !== -1
      ) {
        this.questionnaire.HealthConditionByFamily = ["以上皆無"];
      }
      // if(this.questionnaire.HealthConditionByFamily.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.HealthConditionByFamily = ['以上皆無']
      // }
    },
    changeLatestFootprints(item) {
      // console.log('Myself')questionnaire.latestFootprints
      if (
        item !== "以上皆無" &&
        this.questionnaire.Footprint.indexOf("以上皆無") !== -1
      ) {
        // console.log('1',this.questionnaire.latestFootprints)
        this.questionnaire.Footprint.splice(0, 1);
        // console.log('2',this.questionnaire.latestFootprints)
      } else if (item !== "以上皆無") {
        // console.log('3',this.questionnaire.latestFootprints)
        // this.questionnaire.latestFootprints.slice(1)
        // console.log('4',this.questionnaire.latestFootprints)
      } else if (
        item === "以上皆無" &&
        this.questionnaire.Footprint.indexOf("以上皆無") !== -1
      ) {
        this.questionnaire.Footprint = ["以上皆無"];
      }
      // if(this.questionnaire.latestFootprints.indexOf('以上皆無') !== -1) {
      // this.questionnaire.latestFootprints = ['以上皆無']
      // }
    },
    getFootprintAll() {
      apiGetFootprintAll().then((response) => {
        if (response.status === 200) {
          // console.log(response)
          this.newFootprints = response.data;
          this.newFootprints.push({
            ID: response.data.length + 1,
            Chinese: "以上皆無",
            English: "None of the above",
          });
        }
      });
    },
    getPHSurveyItemsAll() {
      //  取得維護的題目

      apiGetPHSurveyItemsAll().then((response) => {
        // console.log(response);

        if (response.status === 200) {
          this.categories = response.data.map((category) => {
            let tempQuestion = [];
            category.ContentJson.map((question) => {
              if (question.FieldType === "Checkbox") {
                tempQuestion.push({
                  ...question,
                  Value: [],
                });
              } else {
                tempQuestion.push({
                  ...question,
                });
              }
            });

            return {
              ...category,
              ContentJson: tempQuestion,
            };
          });
        }
      });
    },
    async checkPersonalHealthQnrEnabled() {
      //  確認疫調問卷是否開放

      await apiCheckPersonalHealthQnrEnabled().then((response) => {
        // console.log(response);

        if (response.status === 200) {
          this.Enabled = response.data;
        }
      });
    },
  },
  created() {
    this.currentDate = moment().format("YYYY-MM-DD");

    this.checkPersonalHealthQnrEnabled();
  },
  mounted() {
    this.getFootprintAll();
    this.getPHSurveyItemsAll();

    this.getPersonalHealthQnrDesign();

    // this.$refs.form.reset();
  },
  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  },
};
</script>

<style lang="scss" scoped>
.form-page {
  background-color: #bfe3bf;
  text-align: center;
  min-height: 100vh;
  padding: 90px 20px;
  //   background-image: url("~@/assets/img/bg.png");
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 60px 30px;
  }

  .form-title {
    width: 400px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 280px;
      margin: 30px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .text {
      background-image: url("~@/assets/img/title_bg.png");
      padding: 10px 20px;
      border-radius: 44px;
      color: #000;
      display: inline-block;
      font-size: 2em;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 1em;
      }
    }
    .page-number {
      span {
        font-size: 2em;
      }
    }
  }
  .form-wrap {
    width: 550px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .title {
      text-align: center;
      margin-bottom: 15px;

      span {
        display: inline-block;
        background: linear-gradient(rgba(#fff, 0) 60%, #fff 0%) !important;
        font-weight: bold;
        padding: 0 15px;
      }
    }

    .desc {
      font-size: 0.9em;
      line-height: 200%;
      margin-top: 15px;
      margin-bottom: 10px;
      text-align: left !important;
    }

    .field {
      margin-bottom: 10px;
      input {
        border: none;
        padding: 0 10px;
        line-height: 40px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
        &:focus {
          outline: none;
        }
      }

      .el-date-editor {
        width: 100%;
      }
    }

    .checkbox {
      display: block;
      cursor: pointer;
      text-align: left;

      .text {
        &:after {
          content: "\f058";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          color: #b6d3b6;
          position: absolute;
          left: 10px;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          //   color: #d7e995;
          color: #000;
          font-size: 1.2em;
        }

        position: relative;
        // background-color: #97bd97;
        background-color: #fff;
        line-height: 40px;
        display: block;
        padding: 0 10px;
        padding-left: 40px;
        border-radius: 35px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        // color: #fff;
        color: #000;
      }

      input {
        display: none;
        &:checked {
          & + .text {
            // background-color: #039577;
            // color: #fff;
            background-color: #ffff00;
            color: #000;
            &:after {
              //   color: #fff;
              color: #000;
            }
          }
        }
      }
    }

    textarea {
      border: none;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .button-wrap {
      margin-top: 30px;
    }
  }
}

.img-style {
  overflow: hidden;
  z-index: 0;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  img {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: #fff;
  }
}

.btn {
  width: 190px;
  margin: 0.4rem;
}

.ban {
  cursor: not-allowed !important;
}

.prohibit {
  width: 500px;
  display: inline-block;
  // vertical-align: middle;
  padding: 0 30px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  img {
    width: 100%;
    // max-width: 280px;
    margin-bottom: 30px;
  }

  p {
    width: 100%;
    font-size: 1.25rem;
    color: #5a5c69 !important;
    font-weight: 900;
  }
}

#previewModal {
  .modal-dialog {
    min-width: 700px;

    @media screen and (max-width: 767px) {
      width: 100%;
      min-width: auto;
      font-size: 0.7em;
    }
  }

  .title {
    text-align: center;
    background-color: #636f83;
    padding: 20px 0;
    border-radius: 0px;
    color: #fff;
    justify-content: center;
  }

  .category-title {
    font-weight: 900;
  }

  .btn {
    width: auto;
  }
}
</style>
